import chitose_thumb from '../assets/images/avatars_thumbs/chitose_thumb.png';
import nito_thumb from '../assets/images/avatars_thumbs/nito_thumb.png';
import hiyori_thumb from '../assets/images/avatars_thumbs/hiyori_thumb.png';
import shizuku_thumb from '../assets/images/avatars_thumbs/shizuku_thumb.png';
import wanko_thumb from '../assets/images/avatars_thumbs/wanko_thumb.png';
import hijiki_thumb from '../assets/images/avatars_thumbs/hijiki_thumb.png';
import totoro_thumb from '../assets/images/avatars_thumbs/totoro_thumb.png';
import synth01_thumb from '../assets/images/avatars_thumbs/synth1_thumb.png';
import synth02_thumb from '../assets/images/avatars_thumbs/synth2_thumb.png';

const avatares = {
  1: {
    id: 1,
    name: 'Synth01',
    avatar: synth01_thumb,
    background: '/backgrounds/bg_09.jpg',
    thumb: '/backgrounds/bg_thumb_09.jpg',
    model: '/models/Synth01/Synth1.model3.json',
    initialScale: 0.4,
    maxScale: 1.5,
    minScale: 0.256,
    anchor: { x: 0.5, y: 0.3 }
  },
  2: {
    id: 2,
    name: 'Synth02',
    avatar: synth02_thumb,
    background: '/backgrounds/bg_09.jpg',
    thumb: '/backgrounds/bg_thumb_09.jpg',
    model: '/models/Synth02/Synth 02.model3.json',
    initialScale: 0.4,
    maxScale: 1.5,
    minScale: 0.256,
    anchor: { x: 0.5, y: 0.3 }
  },
  3: {
    id: 3,
    name: 'Synth02',
    avatar: shizuku_thumb,
    background: '/backgrounds/bg_09.jpg',
    thumb: '/backgrounds/bg_thumb_09.jpg',
    model: '/models/shizuku/shizuku_t02.model3.json',
    initialScale: 0.4,
    maxScale: 1.5,
    minScale: 0.256,
    anchor: { x: 0.5, y: 0.3 }
  },
  4: {
    id: 4,
    name: 'chitose',
    avatar: chitose_thumb,
    background: '/backgrounds/bg_09.jpg',
    thumb: '/backgrounds/bg_thumb_09.jpg',
    model: '/models/Chitose/chitose_t01.model3.json',
    initialScale: 0.5,
    maxScale: 1.5,
    minScale: 0.256,
    anchor: { x: 0.5, y: 0.3 }
  },
  5: {
    id: 5,
    name: 'nito',
    avatar: nito_thumb,
    background: '/backgrounds/bg_09.jpg',
    thumb: '/backgrounds/bg_thumb_09.jpg',
    model: '/models/Nito/nito_t01.model3.json',
    initialScale: 0.5,
    maxScale: 1.5,
    minScale: 0.215,
    anchor: { x: 0.5, y: 0.3 }
  },
  6: {
    id: 6,
    name: 'wanko_thumb',
    avatar: wanko_thumb,
    background: '/backgrounds/bg_09.jpg',
    thumb: '/backgrounds/bg_thumb_09.jpg',
    model: '/models/Wanko/wanko_touch_t01.model3.json',
    initialScale: 0.5,
    maxScale: 2,
    minScale: 0.5,
    anchor: { x: 0.5, y: 0.4 }
  },
  7: {
    id: 7,
    name: 'hiyori',
    avatar: hiyori_thumb,
    background: '/backgrounds/bg_09.jpg',
    thumb: '/backgrounds/bg_thumb_09.jpg',
    model: '/models/hiyori/hiyori_free_t08.model3.json',
    initialScale: 0.23,
    maxScale: 1,
    minScale: 0.170,
    anchor: { x: 0.5, y: 0.2 }
  },
  8: {
    id: 8,
    name: 'Tororo',
    avatar: totoro_thumb,
    background: '/backgrounds/bg_09.jpg',
    thumb: '/backgrounds/bg_thumb_09.jpg',
    model: '/models/Tororo/tororo_t01.model3.json',
    initialScale: 0.2,
    maxScale: 0.8,
    minScale: 0.1,
    anchor: { x: 0.5, y: 0.2 }
  },
  9: {
    id: 9,
    name: 'Hijiki',
    avatar: hijiki_thumb,
    background: '/backgrounds/bg_09.jpg',
    thumb: '/backgrounds/bg_thumb_09.jpg',
    model: '/models/Hijiki/hijiki_t01.model3.json',
    initialScale: 0.2,
    maxScale: 0.8,
    minScale: 0.1,
    anchor: { x: 0.5, y: 0.2 }
  },
}

export const getAvatares = () => {
  let response = Object.values(avatares);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(response);
    }, 900);
  });
};

export const getAvatar = id => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(avatares[id]);
    }, 900);
  })
}