import React, { useState, useEffect, useRef } from 'react';
import StreamCanvas from './components/StreamCanvas';
import Settings from './components/Settings';
import Header from '../../baseComponents/Header';
import Alert from '../../baseComponents/Alert';
import Loader from '../../baseComponents/Loader';

import { useNavigate, useParams } from 'react-router-dom';
import { getUrl } from '../../utils/routes';
import { getAvatares } from '../../services/avatares';
import { getBackgrounds } from '../../services/backgrounds';
import { getLocals } from './locals';
import { fbAuth } from '../../services/firebase';

import feedbackLogo from '../../assets/images/icons/personaje_rostro.png';
import './index.css';

const Stream = props => {
  const [title, setTitle] = useState('Prueba');
  const [avatar, setAvatar] = useState(null);
  const [avatares, setAvatares] = useState([]);
  const [background, setBackground] = useState(null);
  const [backgrounds, setBackgrounds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showTitle, setShowTitle] = useState(true);
  const [enableCamera, setEnableCamera] = useState(true);
  const [currentTab, setCurrentTab] = useState('title');
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [error, setError] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [alertType, setAlertType] = useState('error');
  const [errorAutoHide, setErrorAutoHide] = useState(true);
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);

  const canvasRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const canvasSize = useRef({ width: 100, height: 100 });

  const navigate = useNavigate();
  const params = useParams();
  let locals = getLocals('en');

  const onLogoutHandler = () => {
    fbAuth.signOut()
      .then(() => {
        navigate(getUrl('home'));
      })
  };

  const onFullScreenHandler = () => {
    const container = document.getElementById('stream');
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
    canvasSize.current = { width: container.offsetWidth, height: container.offsetHeight };
    if (fullscreenElement) {
      const exitFullscreen = document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen;
      exitFullscreen.call(document);
    } else {
      const fullScreen = container.requestFullscreen || container.mozRequestFullScreen || container.webkitRequestFullscreen;
      fullScreen.call(container);
    }
  };
  const onAvatarChangeHandler = avatar => {
    const { id } = params;
    setAvatar(avatar);
    localStorage.setItem(id, JSON.stringify({
      title,
      avatar,
      background,
      streamId: id,
    }));
  };
  const onBackgroundChangeHandler = background => {
    const { id } = params;
    setBackground(background);
    localStorage.setItem(id, JSON.stringify({
      title,
      avatar,
      background,
      streamId: id,
    }));
  };

  const onTitleChange = title => {
    const { id } = params;
    setTitle(title);
    localStorage.setItem(id, JSON.stringify({
      title,
      avatar,
      background,
      streamId: id,
    }));
  };

  const onClickHandler = tab => {
    setCurrentTab(tab);
    setSettingsVisible(true);
    document.getElementById('root').classList.add('stream__menu_active');
  }

  useEffect(() => {
    const { id } = params;
    let stream = localStorage.getItem(id);
    stream = stream ? JSON.parse(stream) : null;

    if (!stream) return navigate(getUrl('newStream'));

    setTitle(stream.title);
    setAvatar(stream.avatar);
    setBackground(stream.background);

    getAvatares()
      .then(response => {
        setAvatares(response);
        return getBackgrounds();
      })
      .then(response => {
        setBackgrounds(response);
        if (navigator.mediaDevices.getUserMedia) return navigator.mediaDevices.getUserMedia({ video: true, audio: false })
          .then(stream => { setEnableCamera(true); setIsLoading(false); })
          .catch(error => {
            setErrorAutoHide(false);
            setEnableCamera(false);
            setIsLoading(false);
            setIsErrorVisible(true);
            setAlertType('error');
            setError(`Camera: ${error.message}`);
          });
        setErrorAutoHide(false);
        setIsErrorVisible(true);
        setAlertType('error');
        setError('Your browser does not support getUserMedia');
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);


  useEffect(() => {
    const prefixes = ['', 'moz', 'webkit', 'ms'];
    const onFullScreenChange = () => {
      const root = document.getElementById('root');

      (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement)
        ? root.classList.add('fullscreen')
        : root.classList.remove('fullscreen');
    };
    prefixes.forEach(prefix => window.addEventListener(`${prefix}fullscreenchange`, onFullScreenChange.bind(this)));
    return () => {
      prefixes.forEach(prefix => window.removeEventListener(`${prefix}fullscreenchange`, onFullScreenChange.bind(this)));
    }
  }, []);

  if (isLoading) return <Loader />;

  if (!avatar) return null;

  return <div id='stream' className='page'>
    <Alert
      autoHide={errorAutoHide}
      type={alertType}
      visible={isErrorVisible}
      onHide={() => {
        setIsErrorVisible(false);
        setAlertType('');
      }}
    >
      <span>{error}</span>
    </Alert>
    <Header
      showBurgerButton={false}
      background={background.background}
      openForm={isFeedbackVisible}
      onClick={(feedback) => setIsFeedbackVisible(feedback)}
    />
    <section className='stream__live'>
      <div id='stream__live_container' ref={canvasRef} className='stream__live_container'>
        <div ref={canvasContainerRef}>
          <StreamCanvas
            enableCamera={enableCamera}
            showTitle={showTitle}
            avatar={avatar}
            background={background.background}
            title={title}
            onLoadingModel={setIsModelLoaded}
            isModelLoaded={isModelLoaded}
          />
        </div>
      </div>
    </section>
    <aside className='stream__menu'>
      <div className='stream__menu_container'>
        <div className='stream__menu_container_buttons'>
          <span onClick={() => onClickHandler('title')} className='icon icon-title-btn btn hide_fs' />
          <span onClick={() => onClickHandler('avatar')} className='icon icon-avatar-btn btn hide_fs' />
          <span onClick={() => onClickHandler('background')} className='icon icon-image-btn btn hide_fs' />
        </div>
        <span onClick={onFullScreenHandler} className='icon icon-fullscreen btn' />
        <span onClick={onLogoutHandler} className='icon icon-sign-out btn hide_fs' />
      </div>
    </aside>
    <Settings
      onFullScreenHandler={onFullScreenHandler}
      isModelLoaded={isModelLoaded}
      visible={settingsVisible}
      activeElement={currentTab}
      onClose={() => setSettingsVisible(false)}
      locals={locals}
      showTitle={showTitle}
      title={title}
      onShowTitleChange={setShowTitle}
      onTitleChange={onTitleChange}
      avatares={avatares}
      currentAvatar={avatar}
      onAvatarChange={onAvatarChangeHandler}
      backgrounds={backgrounds}
      currentBackground={background}
      onBackgroundChange={onBackgroundChangeHandler}
    />
    <div className='stream__feedback'>
      <div onClick={() => setIsFeedbackVisible(!isFeedbackVisible)} className='stream__feedback_text btn'>
        <h2 className='font_azo'>{locals.feedback_one}</h2>
        <p>{locals.feedback_two}</p>
      </div>
      <div onClick={() => setIsFeedbackVisible(!isFeedbackVisible)} className='login_modal stream__feedback_logo btn'>
        <img src={feedbackLogo} alt='cartoober' />
      </div>
    </div>
  </div>;
};

export default Stream;