import React, { useState, useEffect, useRef } from 'react';
import Avatares from './components/Avatares';
import Backgrounds from './components/Backgrounds';
import Header from '../../baseComponents/Header';
import Loader from '../../baseComponents/Loader';

import { useNavigate } from 'react-router-dom';
import { getUrl } from '../../utils/routes';
import { getAvatares } from '../../services/avatares';
import { getBackgrounds } from '../../services/backgrounds';
import { getLocals } from './locals';
import { wait, loadImage } from '../../utils/asyncs'
import generateUUID from '../../utils/UUID';


import './index.css';

const NewStream = props => {
  const [title, setTitle] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatares, setAvatares] = useState([]);
  const [background, setBackground] = useState(null);
  const [backgrounds, setBackgrounds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [prevBackground, setPrevBackground] = useState(null);
  const backgroundRef = useRef(null);

  const navigate = useNavigate();
  const streamId = generateUUID();
  let locals = getLocals('en');

  const createStreamHandler = () => {
    localStorage.setItem(streamId, JSON.stringify({
      title,
      avatar,
      background,
      streamId,
    }));
    navigate(`${getUrl('stream')}/${streamId}`);
  };

  let btnClass = title.trim().length >= 1 ? 'btn_primary' : 'btn_disabled';

  const onKeyDown = e => {
    if (e.key === 'Enter') e.target.blur();
  };

  const onBackgroundChange = background => {
    if (backgroundRef.current)
      backgroundRef.current.classList.add('blur');
    setBackground(background);
    loadImage(background.background)
      .then(() => wait(0.8))
      .then(() => {
        if (backgroundRef.current)
          backgroundRef.current.classList.remove('blur');
        setPrevBackground(background);
      })
      .catch(err => {
        console.log('loadImage rejected', err);
      });
  };

  const loadResources = async () => {
    const avatares = await getAvatares();
    const backgrounds = await getBackgrounds();
    let avatar = avatares[0];
    setAvatar(avatar);
    setBackground(avatar);
    setPrevBackground(avatar);
    setAvatares(avatares);
    setBackgrounds([avatar, ...backgrounds]);
    setIsLoading(false);
  };

  useEffect(() => {
    loadResources();
  }, []);


  if (isLoading) return <Loader />;

  return <div
    id='new_stream'
    className='page'
    style={{ backgroundImage: `url(${background.background})` }}
  >
    <div
      ref={backgroundRef}
      id='new_stream__blur'
      style={{ backgroundImage: `url(${prevBackground.background})` }}
    />
    <Header
      background={background.background}
    />

    <div className='new_stream__content login_modal'>
      <p>{locals.stream_description}</p>

      <div className='new_stream__input'>
        <label className='font_azo'>{locals.title_input}</label>
        <input
          className='font_italic'
          maxLength={60}
          value={title}
          onChange={e => setTitle(e.target.value)}
          type='text'
          placeholder={locals.title_placeholder}
          onKeyDown={onKeyDown} />
      </div>

      <Avatares
        avatares={avatares}
        currentAvatar={avatar.id}
        onChange={avatar => setAvatar(avatar)}
        title={locals.avatar_title}
      />

      <Backgrounds
        title={locals.background_title}
        backgrounds={backgrounds}
        avatar={avatar}
        current={background}
        onChange={onBackgroundChange} />

      <div className='new_stream__container_submit font_bold'>
        <span onClick={createStreamHandler} className={`btn  ${btnClass}`}>Stream</span>
      </div>
    </div>
  </div>;
};

export default NewStream;