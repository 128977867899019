import React, { useRef } from 'react';
import Slider from 'react-slick';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const AvatarSlider = ({
  avatares = [],
  avatarSelected = 1,
  setAvatarSelected = () => null,
  isLoading = false,
  showAvatar = true,
  showBackground = true,
  type = 'circle',
}) => {
  const sliderRef = useRef(null);
  const onAvatarSelected = (avatar, index) => {
    setAvatarSelected(avatar);
    if (sliderRef.current) sliderRef.current.slickGoTo(index);
  };

  if (isLoading) return <div>
    Cargando...
  </div>;

  const settings = {
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    swipeToSlide: true,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          variableWidth: true,
          arrows: false,
        }
      },
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 7,
          variableWidth: false,
        }
      }
    ]
  };

  return <div className='avatar_slider'>
    <div className='avatar_slider__grid'>
      {
        avatares.map((avatar, index) => {
          let background = showBackground ? { background: `url(${avatar.thumb})` } : {};
          return <div key={avatar.id}>
            <div
              onClick={() => onAvatarSelected(avatar, index)}
              id={avatar.id}
              className={`avatar_slider__item ${avatar.id === avatarSelected ? 'selected' : ''} ${type}`}
              style={background}>
              {
                showAvatar && <img src={avatar.avatar} alt={avatar.name} />
              }
            </div>
          </div>
        })
      }
    </div>
    <div className='avatar_slider__container'>
      <Slider ref={sliderRef} {...settings}>
        {
          avatares.map((avatar, index) => {
            let background = showBackground ? { background: `url(${avatar.thumb})` } : {};
            return <div key={avatar.id}>
              <div
                onClick={() => onAvatarSelected(avatar, index)}
                id={avatar.id}
                className={`avatar_slider__item ${avatar.id === avatarSelected ? 'selected' : ''} ${type}`}
                style={background}>
                {
                  showAvatar && <img src={avatar.avatar} alt={avatar.name} />
                }
              </div>
            </div>
          })
        }
      </Slider>
    </div>
  </div>;
}

export default AvatarSlider;
