import { useNavigate } from 'react-router-dom';
import { getUrl } from '../../utils/routes';
import { getLocals } from '../../utils/locals';

import leftIcon from '../../assets/images/icons/card_cachetes.png';
import rightIcon from '../../assets/images/icons/card_standup.png';
import './index.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab)



const Footer = props => {
  const navigate = useNavigate();
  const onPrivacyHandler = () => navigate(getUrl('privacy'));
  const locals = getLocals('en');

  return <div className='footer main_section'>
    <img className='footer__icon' src={leftIcon} alt='icon' />
    <div>
      <div className='footer__social_container'>
        <a href='https://twitter.com/cartoober' target="_blank"><span><FontAwesomeIcon icon={['fab', 'twitter']} /></span></a>
        <a href='https://www.instagram.com/cartoober.stream/' target="_blank"><span><FontAwesomeIcon icon={['fab', 'instagram']} /></span></a>
        <a href='https://www.facebook.com/cartoober/' target="_blank"><span><FontAwesomeIcon icon={['fab', 'facebook']} /></span></a>
        <a href='https://www.tiktok.com/@cartoober.stream' target="_blank"><span><FontAwesomeIcon icon={['fab', 'tiktok']} /></span></a>
      </div>
      <p className='with_love'>{locals.made_with_love}</p>
      <p className='privacy btn' onClick={onPrivacyHandler}>{locals.privacy_policy}</p>
    </div>
    <img className='footer__icon' src={rightIcon} alt='icon' />
  </div>
};

export default Footer;