import React from 'react';
import AvatarSlider from '../../../baseComponents/AvatarSlider';

const Avatares = ({ avatares, currentAvatar, onChange = () => null, title = '' }) => {
  return <div>
    <div className='new_stream__slider_block'>
      <label className='stream__settings_block_title font_azo'>{title}</label>
    </div>
    <AvatarSlider
      showAvatar={true}
      showBackground={false}
      isLoading={false}
      avatares={avatares}
      avatarSelected={currentAvatar}
      setAvatarSelected={onChange}
    />
  </div>;
};

export default Avatares;