import React, { useState, useEffect } from 'react';
import Avatares from './Avatares';
import Backgrounds from './Backgrounds';

const Settings = ({
  locals,
  showTitle,
  title,
  avatares,
  currentAvatar,
  backgrounds,
  currentBackground,
  isModelLoaded,
  onBackgroundChange = () => null,
  onAvatarChange = () => null,
  onShowTitleChange = () => null,
  onTitleChange = () => null,
  onFullScreenHandler = () => null,
  visible = false,
  activeElement = 'title',
  onClose = () => null,
}) => {
  const [active, setActive] = useState('title');

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      e.target.blur();
      onClose();
    }
  };

  const onCloseHandler = () => {
    document.getElementById('root').classList.remove('stream__menu_active');
    onClose();
  };

  useEffect(() => {
    setActive(activeElement);
  }, [activeElement]);

  if (!visible) return null;

  return <aside className='stream__settings login_modal'>
    <div className='stream__settings_buttons'>
      <div className='stream__settings_close'>
        <span onClick={onCloseHandler} className='icon icon-close btn' />
      </div>
      <div className='stream__settings_options'>
        <span onClick={onFullScreenHandler} className='icon icon-fullscreen btn btn stream__settings_option font_azo' />
        <div>
          <span className={`icon icon-title-btn btn stream__settings_option font_azo ${active === 'title' ? 'active' : ''}`}
            onClick={() => setActive('title')} />
          <span className={`icon icon-avatar-btn btn stream__settings_option font_azo ${active === 'avatar' ? 'active' : ''}`}
            onClick={() => setActive('avatar')} />
          <span className={`icon icon-image-btn btn stream__settings_option font_azo ${active === 'background' ? 'active' : ''}`}
            onClick={() => setActive('background')} />
        </div>
      </div>
    </div>
    <div className='stream__settings_content'>
      {
        active === 'title' && <div className='stream__settings_title stream__settings_block'>
          <input
            onKeyDown={onKeyDown}
            className='font_italic'
            maxLength={60}
            value={title}
            onChange={e => onTitleChange(e.target.value)}
            type='text'
            placeholder={locals.title_placeholder} />
          <span className={`show_title_btn btn icon icon-eye ${showTitle ? 'active' : ''}`} onClick={() => onShowTitleChange(!showTitle)} />
        </div>
      }
      {
        active === 'avatar' && !isModelLoaded && <div className='stream__settings_avatar'>
          <span>Loading model... please wait</span>
        </div>
      }
      {
        isModelLoaded && active === 'avatar' && <div className='stream__settings_avatar'>
          <Avatares
            avatares={avatares}
            currentAvatar={currentAvatar}
            onChange={avatar => onAvatarChange(avatar)}
          />
        </div>
      }
      {
        active === 'background' && <div className='stream__settings_background'>
          <Backgrounds
            backgrounds={backgrounds}
            avatar={currentAvatar}
            current={currentBackground}
            onChange={background => onBackgroundChange(background)}
          />
        </div>
      }
    </div>
  </aside>;
};

export default Settings;