const routes = {
  'home': {
    url: '/',
  },
  'stream': {
    url: '/stream',
  },
  'newStream': {
    url: '/stream/new',
  },
  'contact': {
    url: '/contact',
  },
  'auth': {
    url: '/auth',
  },
  'privacy': {
    url: '/privacy',
  }
}

export const getUrl = routeName => {
  let route = routes[routeName]
  return route ? route.url : '/'
}
