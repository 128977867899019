import React, { useState } from 'react';
import LoginForm from '../../baseComponents/LoginForm';

import { getLocals } from '../../utils/locals';
import { useNavigate } from 'react-router-dom';
import { getUrl } from '../../utils/routes';

import logo from '../../assets/images/cartoober_logo.svg';
import cupIcon from '../../assets/images/icons/caradetaza.svg';
import micIcon from '../../assets/images/icons/afromic.svg';
import './index.css'

const Auth = () => {
  const [title, setTitle] = useState('Create your account');
  const [mode, setMode] = useState('signup');
  const navigate = useNavigate();
  const onHomeHandler = () => navigate(getUrl('home'));
  const onPrivacyHandler = () => navigate(getUrl('privacy'));
  const onChangeModeHandler = mode => {
    setMode(mode);
    if (mode === 'signup') setTitle('Create your account');
    if (mode === 'recovery') setTitle('Recover your account');
    if (mode === 'login') setTitle('Welcome back');
  };
  const locals = getLocals('en');
  const icon = mode === 'signup' ? cupIcon : micIcon;

  return <div id='auth' className='page'>
    <div>
      <div className='auth__header'>
        <img onClick={onHomeHandler} className='logo btn' src={logo} alt='Logo cartoober' />
        <h1>{title}</h1>
        <div />
      </div>
      <div className='auth__content'>
        <img className='auth__icon' src={icon} alt='Cup' />
        <LoginForm onModeChange={onChangeModeHandler} />
      </div>
    </div>
    <p className='privacy btn' onClick={onPrivacyHandler}>{locals.privacy_policy}</p>
  </div>;
};

export default Auth;