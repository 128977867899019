import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getUrl } from '../../../utils/routes';

import image from '../../../assets/images/icons/caradetaza.svg';


const Instructions = () => {
  const navigate = useNavigate();
  const onNewStreamHandler = () => navigate(getUrl('newStream'));

  return <div className='main_section home_instructions'>
    <img className='home_instructions__logo' src={image} alt='icon' />
    <div className='home_instructions__title'>
      <h2>The easiest way</h2>
      <h2>to become a Vtuber</h2>
    </div>
    <div className='home_instructions__container'>
      <div className='home_instructions__instruction'>
        <p>1. Sign up <span className='kansas_semibold pointer' onClick={onNewStreamHandler}>here</span></p>
        <p>for free</p>
      </div>
      <div className='home_instructions__instruction'>
        <p>2. Choose your avatar</p>
        <p>and background</p>
      </div>
      <div className='home_instructions__instruction'>
        <p>3. Stream to your</p>
        <p>favorite caster</p>
      </div>
    </div>
    <span className='btn' onClick={onNewStreamHandler}>Stream now</span>
  </div>
};

export default Instructions;
