import React from 'react';

import { useNavigate } from 'react-router-dom';
import { getUrl } from '../../utils/routes';

import logo from '../../assets/images/cartoober_logo.svg';
import guy from '../../assets/images/icons/personaje_rostro.png';
import './index.css';

const HeaderFull = props => {
  const navigate = useNavigate();
  const onStreamHandler = () => navigate(getUrl('newStream'));
  const onContactHandler = () => navigate(getUrl('contact'));
  const onHomeHandler = () => navigate(getUrl('home'));

  return <div className='header_full'>
    <img onClick={onHomeHandler} className='logo btn' src={logo} alt='Logo cartoober' />
    <div className='header_full__buttons'>
      <span className='btn btn_black' onClick={onStreamHandler}>Stream</span>
      <span className='btn' onClick={onContactHandler}>
        <img className='icon_image' src={guy} alt='Personaje rostro' />
        Contact
      </span>
    </div>
  </div>
};

export default HeaderFull;