const langs = {
  en: {
    'stream_description': `Stream as an animated avatar from your browser.`,
    'title_input': 'TITLE',
    'title_placeholder': 'Title...',
    'avatar_title': 'AVATAR',
    'background_title': 'BACKGROUND',
    'feedback_one': `That's not all folks!`,
    'feedback_two': 'we need your feedback',
  },
  es: {
    'stream_description': `Stream as an animated avatar from your browser.`,
    'title_input': 'TITLE',
    'title_placeholder': 'Title...',
    'avatar_title': 'AVATAR',
    'background_title': 'BACKGROUND',
    'feedback_one': `That's not all folks!`,
    'feedback_two': 'we need your feedback',
  },
};


export const getLocals = (lang = 'en') => {
  return {
    ...langs[lang],
  };
};
