import React, { useState, useEffect } from 'react';
import HeaderFull from '../../baseComponents/HeaderFull';
import Alert from '../../baseComponents/Alert';
import Hero from '../../baseComponents/Hero';
import Footer from '../../baseComponents/Footer';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getUrl } from '../../utils/routes';
import { getLocals } from '../../utils/locals';
import './index.css'

const Contact = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [alertType, setAlertType] = useState('error');
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const onNewStreamHandler = () => navigate(getUrl('newStream'));
  const locals = getLocals('en');

  const onSubmit = () => {
    if (email.trim().length === 0) {
      setError(locals.empty_email);
      setAlertType('error');
      setIsVisible(true);
      return;
    }
    // eslint-disable-next-line
    let emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      setAlertType('error');
      setError(locals.invalid_email);
      setIsVisible(true);
      return;
    }

    if (message.trim().length === 0) {
      setError(locals.empty_message);
      setAlertType('error');
      setIsVisible(true);
      return;
    }

    setIsLoading(true);
    axios.post('https://hoppia.herokuapp.com/cartoober-contact', { name: email, email, message })
      .then(() => {
        setAlertType('success');
        setError(locals.success_message);
        setIsVisible(true);
        setIsLoading(false);
        setEmail('');
        setMessage('');
      })
      .catch(() => {
        setError(locals.error_message);
        setAlertType('error');
        setIsVisible(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    axios.get('https://hoppia.herokuapp.com/hello');
  }, []);

  return <div id='contact' className='page'>
    <HeaderFull />
    <div className='contact__form'>
      <div className='contact__form_info'>
        <div>
          <h2>{locals.contact_us}</h2>
          <div className='contact__info'>
            <p>hello@cartoober.com</p>
            <p>+1 52 55 3476 5716</p>
            <p>Mexico city</p>
          </div>
        </div>
        <div className='contact__form_social'>

        </div>
      </div>
      <div className='contact__form_fields'>
        <h2>{locals.message_us}</h2>
        <div className='contact__form_container'>
          <div className='login_form__field'>
            <label className='main_bg' htmlFor='email'>Email</label>
            <input id='email' name='email' type='text'
              value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className='login_form__field'>
            <label className='main_bg' htmlFor='email'>Message</label>
            <textarea id='email' name='email' rows={5} type='text'
              value={message} onChange={e => setMessage(e.target.value)} />
          </div>
        </div>
        <div>
          {
            !isLoading
              ? <span onClick={onSubmit} className='btn send_btn'>{locals.send_btn}</span>
              : <span className='btn send_btn'>{locals.loading_message}</span>
          }
        </div>
      </div>
      <Alert
        type={alertType}
        visible={isVisible}
        onHide={() => {
          setIsVisible(false);
          setAlertType('');
        }}
      >
        <span>{error}</span>
      </Alert>
    </div>
    <Hero />
    <div className='main_section stream_button'>
      <div className='btn' onClick={onNewStreamHandler}>Stream now</div>
    </div>
    <Footer />
  </div>;
};

export default Contact;