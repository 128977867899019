import React from 'react';
import AvatarSlider from '../../../baseComponents/AvatarSlider';

const Avatares = ({ avatares, currentAvatar, onChange = () => null }) => {
  return <div>
    <AvatarSlider
      showAvatar={true}
      showBackground={false}
      isLoading={false}
      avatares={avatares}
      avatarSelected={currentAvatar.id}
      setAvatarSelected={onChange}
    />
  </div>;
};

export default Avatares;