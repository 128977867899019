import React, { useContext } from 'react';
import LoginForm from '../LoginForm';
import { AuthContext } from '../../providers/AuthProvider';
import Embed from '../../baseComponents/Embed'

import './index.css'

const Hero = props => {
  const { user } = useContext(AuthContext);

  const onPlayPause = () => {
    var iframe = document.querySelector('iframe')
    var player = new window.Vimeo.Player(iframe)
    player.on('play', function () {
      player.setVolume(0.2)
    });
    player.getPaused().then(function (paused) {
      paused ? player.play() : player.pause()
    })
  }

  return <div className='hero'>
    <div className='hero__info'>
      <div className='hero__info_title'>
        <h2>Stream as an <span className='red_color'>animated</span></h2>
        <h2>avatar from your browser</h2>
      </div>
      <div className="video_container">
        <Embed>
          <iframe
            src="https://player.vimeo.com/video/692450230?autoplay=0&loop=1&autopause=0&title=0&byline=0&portrait=0&sidedock=0"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Cartoober" />
        </Embed>
      </div>
    </div>
    <div className='hero__login'>
      {
        Boolean(user)
          ? <div className='empty_space' />
          : <LoginForm />
      }
    </div>
  </div>;
}

export default Hero;