import React, { useEffect, useState, createContext } from 'react';
import Loader from '../baseComponents/Loader';

import { fbAuth } from '../services/firebase';

export const AuthContext = createContext();

export const AuthProvider = props => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fbAuth.onAuthStateChanged(user => {
      setUser(user);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <Loader />;

  return <AuthContext.Provider value={{ user }}>
    {props.children}
  </AuthContext.Provider>
};
