import React, { useContext } from 'react';
import HeaderFull from '../../baseComponents/HeaderFull';
import Hero from '../../baseComponents/Hero';
import Instructions from './components/Instructions';
import Footer from '../../baseComponents/Footer';
import { AuthContext } from '../../providers/AuthProvider';
import NewStream from '../NewStream';

import './index.css';

const Home = props => {
  return <div id='home' className='page'>
    <HeaderFull />
    <Hero />
    <Instructions />
    <Footer />
  </div>;
};

const HomeWrapper = props => {
  const { user } = useContext(AuthContext);
  return !user ? <Home {...props} /> : <NewStream />;
};

export default HomeWrapper;