import logo from '../../assets/images/icons/loading.png';
import cartoober from '../../assets/images/cartoober_logo.svg';
import './index.css';

const Loader = props => {
  return <div className='page cartoober__loader'>
    <div className='cartoober__loader_content'>
      <img className='cartoober__loader_image' src={logo} alt='loading' />
      <img className='cartoober__loader_logo' src={cartoober} alt='cartoober' />
      <span className='font_azo'>Loading</span>
      {
        /*
        <div className='cartoober__loader__progressbar' />
        */
      }
    </div>
  </div>
};

export default Loader;