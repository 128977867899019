const langs = {
  en: {
    'stream_description': `Stream as an animated avatar from your browser.`,
    'title_input': 'TITLE',
    'title_placeholder': 'Enter name',
    'avatar_title': 'AVATAR',
    'background_title': 'BACKGROUND',
    'contact_us': 'CONTACT US',
    'input_name': 'Name',
    'input_email': 'E-mail',
    'input_message': 'Message',
    'made_with_love': 'Made with love from Mexico City',
    'privacy_policy': 'Privacy Policy',
    'send_btn': 'SEND',
    'message_us': 'MESSAGE US',
    'empty_name': 'Name is required',
    'empty_email': 'E-mail is required',
    'empty_message': 'Message is required',
    'invalid_email': 'Please enter a valid email address.',
    'error_message': 'Error sending message',
    'success_message': 'Message sent successfully',
    'loading_message': 'Sending message...',
    'contact_form_desc': 'We love to hear from you. Give us your feedback!.',
  },
  es: {
    'stream_description': `Stream as an animated avatar from your browser.`,
    'title_input': 'TITLE',
    'title_placeholder': 'Enter name',
    'avatar_title': 'AVATAR',
    'background_title': 'BACKGROUND',
    'contact_us': 'CONTACT US',
    'input_name': 'NAME',
    'input_email': 'EMAIL',
    'input_message': 'MESSAGE',
    'made_with_love': 'Made with love from Mexico City',
    'privacy_policy': 'Privacy Policy',
    'send_btn': 'SEND',
    'message_us': 'MESSAGE US',
    'empty_name': 'Name is required',
    'empty_email': 'E-mail is required',
    'empty_message': 'Message is required',
    'invalid_email': 'Please enter a valid email address.',
    'error_message': 'Error sending message',
    'success_message': 'Message sent successfully',
    'loading_message': 'Sending message...',
    'contact_form_desc': 'We love to hear from you. Give us your feedback!.',
  },
};


export const getLocals = (lang = 'en') => {
  return {
    ...langs[lang],
  };
};
