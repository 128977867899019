import React, { useState, useEffect } from 'react';
import { authWithEmailAndPassword, createUserWithEmail, facebookAuthProvider } from '../../services/firebase';

import './index.css'

const LoginForm = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mode, setMode] = useState('signup');
  const [error, setError] = useState('');

  const onModeChangeHandler = mode => {
    setMode(mode);
    if (typeof props.onModeChange === 'function') props.onModeChange(mode);
  };

  const onCatchError = (error) => {
    if (typeof error === 'string') setError(error);
  };

  const onSubmitHandler = () => {
    setError('');
    if (mode === 'signup') {
      createUserWithEmail(email, password, onCatchError);
    } else {
      authWithEmailAndPassword(email, password, onCatchError);
    }
  };

  useEffect(() => {
    if (typeof props.onModeChange === 'function') props.onModeChange(mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (mode === 'recovery') return <div className='login_form'>
    <div className='login_form__fields'>
      <div className='login_form__field'>
        <label className='main_bg' htmlFor='email'>Email</label>
        <input id='email' name='email' type='text'
          value={email} onChange={e => setEmail(e.target.value)} />
      </div>
    </div>
    <button className='btn login_form__btn'>Change password</button>
  </div>;

  return <div className='login_form'>
    <div className='login_form__social d-none'>
      <button className=''>
        <span>Continue with Google</span>
      </button>
      <button onClick={facebookAuthProvider}>
        <span>Continue with Facebook</span>
      </button>
    </div>
    <div className='login_form__divider d-none'>
      <div className='line' />
      <p>or</p>
      <div className='line' />
    </div>
    <div className='login_form__fields'>
      <div className='login_form__field'>
        <label className='' htmlFor='email'>Email</label>
        <input id='email' name='email' type='text'
          value={email} onChange={e => setEmail(e.target.value)} />
      </div>
      <div className='login_form__field'>
        <label className='' htmlFor='password'>Password</label>
        <input id='password' name='password' type='password'
          value={password} onChange={e => setPassword(e.target.value)} />
      </div>
    </div>
    <div className='login_form__buttons'>
      <button onClick={onSubmitHandler} className='btn login_form__btn'>
        {
          mode === 'signup'
            ? 'Sign up for free'
            : 'Login'
        }
      </button>
    </div>
    {
      mode === 'login' &&
      <div className='login_form__options'>
        <p>
          New to cartoober? <b className='btn' onClick={() => onModeChangeHandler('signup')}>Sign up</b>
          <span className='divider'>/</span>
          {
            /*
            <b className='btn' onClick={() => onModeChangeHandler('recovery')}>Forgot your password?</b>
            */
          }
        </p>
      </div>
    }
    {
      mode === 'signup' &&
      <div className='login_form__options'>
        <p>Already have an account? <b className='btn' onClick={() => onModeChangeHandler('login')}>Log in</b></p>
      </div>
    }
    <span className='error'>{error}</span>
  </div>
};

export default LoginForm;