import React, { useState, useEffect } from 'react';
import ContactForm from '../ContactForm';

import logo from '../../assets/images/cartoober_logo.svg';
import './index.css';

const Header = ({
  background,
  showBurgerButton = true,
  openForm = false,
  onClick = () => null,
}) => {
  const [isOpen, setIsOpen] = useState(openForm);

  useEffect(() => setIsOpen(openForm), [openForm]);

  return <div className={`cartoober__header login_modal ${showBurgerButton ? '' : 'simple_mode'}`}>
    <img className='logo' src={logo} alt='Logo cartoober' />
    <span className='icon icon-burger btn burguer_menu'
      onClick={() => { setIsOpen(!isOpen); onClick(!isOpen); }} />
    <ContactForm visible={isOpen} background={background} onClose={() => { setIsOpen(false); onClick(false); }} />
  </div>;
};

export default Header;  